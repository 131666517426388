<template>
  <b-card body-class="text-center">
    <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
    <h4>{{ $t('passwordReset.resetErrorTitle') }}</h4>
    <p
      class="reset-link mb-1"
      @click="$emit('reset-password-component')">
      {{ $t('passwordReset.resetErrorMsg') }}
    </p>
    <p v-html="$t('passwordReset.resetErrorGotoMsg')"></p>
  </b-card>
</template>

<script>
import { Auth } from '@aws-amplify/auth';

export default {
  name: 'PasswordResetSuccessCard',
  async created() {
    await Auth.signOut({ global: true });
  },
};
</script>

<style lang="scss" scoped>
.reset-link {
  color: #51d182;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
